import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { ImArrowUp } from "react-icons/im";
import Hero from "../components/hero";
import Rice from "../components/menu_components/rice";
import ChowMein from "../components/menu_components/chowmein";
import Vermicelli from "../components/menu_components/vermicelli";
import Dishes from "../components/menu_components/dishes";
import Appetisers from "../components/menu_components/appetisers";
import Sauces from "../components/menu_components/sauces";
import HoFun from "../components/menu_components/hofun";
import "../components/css/navbar.css";
import "../components/css/layout_main.css";
import "../components/css/menu.css";

const MenuPage = () => {
  const [Category, setCategory] = useState("appetisers");
  const [Heading, setHeading] = useState("Appetisers");

  const changeCategory = (e) => {
    setCategory(e.target.value);
    switch (e.target.value) {
      case "rice":
        setHeading("Fried Rice");
        break;
      case "chowmein":
        setHeading("Chow Mein");
        break;
      case "vermicelli":
        setHeading("Vermicelli");
        break;
      case "dishes":
        setHeading("Meals");
        break;
      case "appetisers":
        setHeading("Appetisers");
        break;
      case "sauces":
        setHeading("Sauces");
        break;
      case "hofun":
        setHeading("Ho Fun");
        break;
      default:
        return null;
    }
  };

  const inactiveBtn = () => {
    let allButtons = document.getElementsByClassName("menu_buttons");
    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].classList.remove("menu_button_active");
    }
  };

  const activeBtn = (e) => {
    inactiveBtn();
    e.target.className += " menu_button_active";
    changeCategory(e);
  };
  // scroll listener
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.getElementById("scrollup")) {
        let scroller = document.getElementById("scrollup");
        if (
          window.scrollY > 200 &&
          window.matchMedia("(max-width: 575px").matches
        ) {
          scroller.style.display = "block";
        } else {
          scroller.style.display = "none";
        }
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Layout pageTitle="Oriental - Menu" pageHeading="Menu" pageHeadText="">
      <Hero heroTitle="Menu" heroText="" />
      <div className="menu_background">
        <Container>
          <Row className="">
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons menu_button_active"
                type="button"
                value="appetisers"
                onClick={activeBtn}
              >
                Appetisers
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="dishes"
                onClick={activeBtn}
              >
                Meals
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="hofun"
                onClick={activeBtn}
              >
                Ho Fun
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="rice"
                onClick={activeBtn}
              >
                Fried Rice
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="chowmein"
                onClick={activeBtn}
              >
                Chow Mein
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="vermicelli"
                onClick={activeBtn}
              >
                Vermicelli
              </button>
            </Col>
            <Col className="menu_cols" sm="2" xs="4">
              <button
                className="menu_buttons"
                type="button"
                value="sauces"
                onClick={activeBtn}
              >
                Sauces
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className="menu-content">
          <Row className="justify-content-center">
            <p className="category-heading">{Heading}</p>
          </Row>

          <Row className="justify-content-center">
            <Col className="allergy" sm="5" xs="12">
              <p className="allergy-heading">Allergy Advice:</p>
              <p className="allergy-content">
                If you have any allergies or dietary requirements, please let us
                know before ordering. Our dishes may contain: Crustaceans,
                Molluscs, Nuts, Milk, Sesame Seeds, Fish, Mustard, Eggs, Soya,
                Gluten, Sulphur Dioxide, Lupin, Peanuts.
              </p>
            </Col>

            <Col className="extra" sm={{ span: 5, offset: 1 }} xs="12">
              <table className="extra-table">
                <tbody>
                  <tr className="extra-heading">
                    <th className="extra-item">Extra Toppings:</th>
                    <th className="extra-price">Price:</th>
                  </tr>
                  <tr>
                    <td className="extra-item">Green chilli</td>
                    <td className="extra-price">10p</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Chicken</td>
                    <td className="extra-price">£1.50</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Roast pork</td>
                    <td className="extra-price">£1.50</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Beef</td>
                    <td className="extra-price">£2.00</td>
                  </tr>
                  <tr>
                    <td className="extra-item">King prawn</td>
                    <td className="extra-price">£2.00</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Duck</td>
                    <td className="extra-price">£2.00</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Ribs</td>
                    <td className="extra-price">£1.50</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Shrimp</td>
                    <td className="extra-price">£2.00</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Vegetables</td>
                    <td className="extra-price">£1.50</td>
                  </tr>
                  <tr>
                    <td className="extra-item">Tofu</td>
                    <td className="extra-price">£1.00</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row className="justify-content-sm-center">
            <p className="disclaimer">
              Prices valid from January 2023 and are subject to changes without
              prior notice. Prices applicable to in-house menu only.
              {/* If you would
              like to place a delivery order please visit us on Deliveroo.*/}
            </p>
          </Row>

          <Row>
            <Container className="category-container">
              {(() => {
                switch (Category) {
                  case "rice":
                    return <Rice />;
                  case "chowmein":
                    return <ChowMein />;
                  case "vermicelli":
                    return <Vermicelli />;
                  case "dishes":
                    return <Dishes />;
                  case "appetisers":
                    return <Appetisers />;
                  case "sauces":
                    return <Sauces />;
                  case "hofun":
                    return <HoFun />;
                  default:
                    return null;
                }
              })()}
              <div>
                <button onClick={scrollUp} className="scrollup" id="scrollup">
                  <ImArrowUp />
                </button>
              </div>
            </Container>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default MenuPage;
