const SauceItems = [
  {
    id: "601",
    name: "Chilli oil (4oz)",
    price: "60p",
  },
  {
    id: "602",
    name: "Sweet and Sour (8oz)",
    price: "£1.50",
  },
  {
    id: "603",
    name: "Curry (8oz)",
    price: "£1.50",
  },
  {
    id: "604",
    name: "Barbecue (8oz)",
    price: "£1.50",
  },
  {
    id: "605",
    name: "Sweet chilli (4oz)",
    price: "£1.00",
  },
];

export default SauceItems;
