const ChowMeinItems = [
  {
    id: "401",
    name: "Special chow mein",
    desc: "With pork, chicken, and shrimp.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "402",
    name: "Spicy special chow mein",
    desc: "With pork, chicken, shrimp and chilli.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "403",
    name: "Singapore chow mein",
    desc: "With pork, chicken, shrimp, curry powder and spring onion.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "404",
    name: "Mixed meat chow mein",
    desc: "With pork, chicken and beef.",
    price: "£7.50",
    vegetarian: "",
  },
  {
    id: "405",
    name: "Chicken chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "406",
    name: "Beef chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "407",
    name: "Duck chow mein",
    desc: "",
    price: "£7.80",
    vegetarian: "",
  },
  {
    id: "408",
    name: "Roast pork chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "409",
    name: "Spare ribs chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "410",
    name: "King prawn chow mein",
    desc: "",
    price: "£7.80",
    vegetarian: "",
  },
  {
    id: "411",
    name: "Shrimp chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },

  {
    id: "412",
    name: "Vegetable and tofu chow mein",
    desc: "",
    price: "£7.30",
    vegetarian: "V",
  },
  {
    id: "413",
    name: "Plain chow mein",
    desc: "",
    price: "£4.80",
    vegetarian: "V",
  },
];

export default ChowMeinItems;
