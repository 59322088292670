import React from "react";
import DishItems from "./items/dish_items";
import { Col, Row } from "react-bootstrap";

const Dishes = () => {
  return (
    <>
      <Row className="justify-content-sm-center">
        <p className="disclaimer-dish">
          All meals are served with Boiled Rice. You can change it to: Fried
          Rice / Chow Mein / Vermicelli for 60p, Special Fried Rice for £1.60
        </p>
      </Row>
      {DishItems.map(
        (item) =>
          item.sub_cat === "house-special" && (
            <Row className="food-item" key={item.id.concat(item.name)}>
              <Col sm="1" xs="2">
                <p className="food-id">{item.id}</p>
              </Col>
              <Col sm="8" xs="6">
                <p className="food-info-name">
                  {item.name}
                  <span> {item.vegetarian}</span>
                </p>
                <p className="food-info-desc">{item.desc}</p>
              </Col>
              <Col sm="3" xs="4" className="px-0">
                <p className="food-price">Meal: {item.meal}</p>
                <p className="food-price">Dish: {item.dish}</p>
              </Col>
            </Row>
          )
      )}
      {/*Sub-Category sweet-sour*/}
      <div className="food-item">
        <Row>
          <Col sm="12" xs={{ span: 11, offset: 1 }}>
            <p className="food-info-name">Sweet and sour (Hong Kong style)</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "sweet-sour" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category ribs*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Ribs</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "ribs" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category spicy*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Spicy salt and pepper</p>
            <p className="food-info-desc">
              With chilli, onions, spring onions and garlic.
            </p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "spicy" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category  Veg*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name"> Vegetables and Gravy</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "mixed-veg" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category chilli-bean*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Vegetables and chilli-bean sauce</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "chilli-bean" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category green-pepper*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Green pepper and black bean sauce</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "green-pepper" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category curry*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Curry</p>
            <p className="food-info-desc">With onions.</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "curry" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category Sweet chilli*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Deep fried sweet chilli</p>
            <p className="food-info-desc">With carrots and sesame seeds.</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "sweet-chilli" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category brocolli*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Broccoli and gravy</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "broccoli" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
      {/*Sub-Category mushroom*/}
      <div className="food-item">
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p className="food-info-name">Mushroom and gravy</p>
          </Col>
        </Row>
        {/**/}
        {DishItems.map(
          (item) =>
            item.sub_cat === "mushroom" && (
              <Row className="food-item-list" key={item.id.concat(item.name)}>
                <Col sm="1" xs="2">
                  <p className="food-id">{item.id}</p>
                </Col>
                <Col sm="8" xs="6">
                  <p className="food-info-name">
                    {item.name} <span>{item.vegetarian}</span>
                  </p>
                  <p className="food-info-desc">{item.desc}</p>
                </Col>
                <Col sm="3" xs="4" className="px-0">
                  <p className="food-price">Meal: {item.meal}</p>
                  <p className="food-price">Dish: {item.dish}</p>
                </Col>
              </Row>
            )
        )}
      </div>
    </>
  );
};

export default Dishes;
