const DishItems = [
  {
    id: "201",
    name: "Oriental House Special",
    desc: "Stir fried vegetables with beef, chicken and king prawns cooked in gravy.",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "house-special",
  },
  {
    id: "202",
    name: "House curry",
    desc: "With king prawn, chicken and beef.",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "house-special",
  },
  {
    id: "203",
    name: "Chicken",
    desc: "",
    dish: "£7.50",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "sweet-sour",
  },
  {
    id: "204",
    name: "Pork",
    desc: "",
    dish: "£7.50",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "sweet-sour",
  },
  {
    id: "205",
    name: "King prawns",
    desc: "",
    dish: "£7.50",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "sweet-sour",
  },

  {
    id: "206",
    name: "Sweet and sour ribs",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "ribs",
  },
  {
    id: "207",
    name: "Barbecue ribs",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "ribs",
  },
  {
    id: "208",
    name: "Spicy salt and pepper ribs",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "ribs",
  },
  {
    id: "209",
    name: "Chicken",
    desc: "",
    dish: "£7.50",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "spicy",
  },
  {
    id: "210",
    name: "Beef",
    desc: "",
    dish: "£7.80",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "spicy",
  },
  {
    id: "211",
    name: "King prawn",
    desc: "",
    dish: "£7.50",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "spicy",
  },
  {
    id: "212",
    name: "Tofu",
    desc: "",
    dish: "£7.50",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "spicy",
  },
  {
    id: "213",
    name: "Chicken",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "mixed-veg",
  },
  {
    id: "214",
    name: "Beef",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "mixed-veg",
  },
  {
    id: "215",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "mixed-veg",
  },
  {
    id: "216",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "mixed-veg",
  },
  {
    id: "217",
    name: "Tofu",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "V",
    sub_cat: "mixed-veg",
  },
  {
    id: "218",
    name: "Plain",
    desc: "",
    dish: "£5.80",
    meal: "£6.30",
    vegetarian: "V",
    sub_cat: "mixed-veg",
  },
  {
    id: "219",
    name: "Chicken",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "chilli-bean",
  },
  {
    id: "220",
    name: "Beef",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "chilli-bean",
  },
  {
    id: "221",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "chilli-bean",
  },
  {
    id: "222",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "chilli-bean",
  },
  {
    id: "223",
    name: "Tofu",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "V",
    sub_cat: "chilli-bean",
  },
  {
    id: "224",
    name: "Plain",
    desc: "",
    dish: "£5.80",
    meal: "£6.30",
    vegetarian: "V",
    sub_cat: "chilli-bean",
  },
  {
    id: "225",
    name: "Chicken",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "green-pepper",
  },
  {
    id: "226",
    name: "Beef",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "green-pepper",
  },
  {
    id: "227",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "green-pepper",
  },
  {
    id: "228",
    name: "Roast Pork",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "green-pepper",
  },
  {
    id: "229",
    name: "Ribs",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "green-pepper",
  },
  {
    id: "230",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "green-pepper",
  },

  {
    id: "231",
    name: "Chicken",
    desc: "",
    dish: "£6.80",
    meal: "£7.30",
    vegetarian: "",
    sub_cat: "curry",
  },
  {
    id: "232",
    name: "Beef",
    desc: "",
    dish: "£6.80",
    meal: "£7.30",
    vegetarian: "",
    sub_cat: "curry",
  },
  {
    id: "233",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "curry",
  },
  {
    id: "234",
    name: "Roast Pork",
    desc: "",
    dish: "£6.80",
    meal: "£7.30",
    vegetarian: "",
    sub_cat: "curry",
  },
  {
    id: "235",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "curry",
  },
  {
    id: "236",
    name: "Vegetable",
    desc: "",
    dish: "£5.80",
    meal: "£6.30",
    vegetarian: "V",
    sub_cat: "curry",
  },

  {
    id: "237",
    name: "Chicken",
    desc: "",
    dish: "£7.50",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "sweet-chilli",
  },
  {
    id: "238",
    name: "Beef",
    desc: "",
    dish: "£7.80",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "sweet-chilli",
  },
  {
    id: "239",
    name: "King prawn",
    desc: "",
    dish: "£7.50",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "sweet-chilli",
  },
  {
    id: "240",
    name: "Chicken",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "broccoli",
  },
  {
    id: "241",
    name: "Beef",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "broccoli",
  },
  {
    id: "242",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "broccoli",
  },
  {
    id: "243",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "broccoli",
  },
  {
    id: "244",
    name: "Plain",
    desc: "",
    dish: "£5.80",
    meal: "£6.30",
    vegetarian: "V",
    sub_cat: "broccoli",
  },
  {
    id: "245",
    name: "Chicken",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "mushroom",
  },
  {
    id: "246",
    name: "Beef",
    desc: "",
    dish: "£7.00",
    meal: "£7.50",
    vegetarian: "",
    sub_cat: "mushroom",
  },
  {
    id: "247",
    name: "Duck",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "mushroom",
  },
  {
    id: "248",
    name: "King prawn",
    desc: "",
    dish: "£7.30",
    meal: "£7.80",
    vegetarian: "",
    sub_cat: "mushroom",
  },
  {
    id: "249",
    name: "Plain",
    desc: "",
    dish: "£5.80",
    meal: "£6.30",
    vegetarian: "V",
    sub_cat: "mushroom",
  },
];

export default DishItems;
