const vermicelliItems = [
  {
    id: "501",
    name: "Special vermicelli",
    desc: "With pork, chicken and shrimp.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "502",
    name: "Singapore vermicelli",
    desc: "With pork, chicken, shrimp, curry powder and spring onion.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "503",
    name: "Mixed meat vermicelli",
    desc: "With pork, chicken and beef.",
    price: "£7.50",
    vegetarian: "",
  },
  {
    id: "504",
    name: "Chicken vermicelli",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "505",
    name: "Beef vermicelli",
    desc: "",
    price: "£7.50",
    vegetarian: "",
  },
  {
    id: "506",
    name: "Duck vermicelli",
    desc: "",
    price: "£8.00",
    vegetarian: "",
  },
  {
    id: "507",
    name: "Roast Pork vermicelli",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "508",
    name: "King Prawn vermicelli",
    desc: "",
    price: "£8.00",
    vegetarian: "",
  },
  {
    id: "509",
    name: "Shrimp vermicelli",
    desc: "",
    price: "£7.50",
    vegetarian: "",
  },

  {
    id: "510",
    name: "Vegetable and tofu vermicelli",
    desc: "",
    price: "£7.30",
    vegetarian: "V",
  },

  {
    id: "511",
    name: "Plain vermicelli",
    desc: "",
    price: "£5.00",
    vegetarian: "V",
  },
];

export default vermicelliItems;
