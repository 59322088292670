import React from "react";
import AppetisersItems from "./items/appetiser_items";
import { Col, Row } from "react-bootstrap";

const Appetisers = () => {
  return (
    <>
      {AppetisersItems.map((item) => (
        <div className="food-item" key={item.id.concat(item.name)}>
          <Row>
            <Col sm="1" xs="2">
              <p className="food-id">{item.id}</p>
            </Col>
            <Col sm="9" xs="6">
              <p className="food-info-name">
                {item.name}
                <span> {item.vegetarian}</span>
              </p>
              <p className="food-info-desc">{item.desc}</p>
            </Col>
            <Col sm="2" xs="4">
              <p className="food-price">{item.price}</p>
            </Col>
          </Row>
          <Row>
            <Col sm="1" xs="2"></Col>
            <Col sm="9" xs="6">
              <p className="food-extra">{item.extra_1}</p>
              <p className="food-extra">{item.extra_2}</p>
              <p className="food-extra">{item.extra_3}</p>
            </Col>
            <Col className="" sm="2" xs="4">
              <p className="food-price food-extra">{item.extra_1_price}</p>
              <p className="food-price food-extra">{item.extra_2_price}</p>
              <p className="food-price food-extra">{item.extra_3_price}</p>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

export default Appetisers;
