const RiceItems = [
  {
    id: "301",
    name: "Special fried rice",
    desc: "With pork, chicken, and shrimp.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "302",
    name: "Spicy special fried rice",
    desc: "With pork, chicken, shrimp, chilli and spring onion. No peas.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "303",
    name: "Singapore fried rice",
    desc: "With pork, chicken, shrimp, curry powder and spring onion. No peas.",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "304",
    name: "Dry beef & onion special fried rice",
    desc: "With pork, chicken and shrimp.",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "305",
    name: "Mixed meat fried rice",
    desc: "With pork, chicken and beef.",
    price: "£7.50",
    vegetarian: "",
  },
  {
    id: "306",
    name: "Egg fried rice (no peas)",
    desc: "",
    price: "£4.80",
    vegetarian: "",
  },

  {
    id: "307",
    name: "Chicken fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "308",
    name: "Beef fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "309",
    name: "Duck fried rice",
    desc: "",
    price: "£7.80",
    vegetarian: "",
  },
  {
    id: "310",
    name: "Roast pork fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "311",
    name: "Spare ribs fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "312",
    name: "King prawn fried rice",
    desc: "",
    price: "£7.80",
    vegetarian: "",
  },
  {
    id: "313",
    name: "Shrimp fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "",
  },
  {
    id: "314",
    name: "Vegetable and tofu fried rice",
    desc: "",
    price: "£7.30",
    vegetarian: "V",
  },
  {
    id: "315",
    name: "Plain fried rice (no peas)",
    desc: "",
    price: "£4.80",
    vegetarian: "V",
  },
  {
    id: "316",
    name: "Boiled rice (no peas)",
    desc: "",
    price: "£3.30",
    vegetarian: "V",
  },
];

export default RiceItems;
