import React from "react";
import ChowMeinItems from "./items/chowmein_items";
import { Row, Col } from "react-bootstrap";

const ChowMein = () => {
  return (
    <>
      {ChowMeinItems.map((item) => (
        <div className="food-item" key={item.id.concat(item.name)}>
          <Row>
            <Col sm="1" xs="2">
              <p className="food-id">{item.id}</p>
            </Col>
            <Col sm="9" xs="6">
              <p className="food-info-name">
                {item.name}
                <span> {item.vegetarian}</span>
              </p>
              <p className="food-info-desc">{item.desc}</p>
            </Col>
            <Col sm="2" xs="4">
              <p className="food-price">{item.price}</p>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

export default ChowMein;
