const HofunItems = [
  {
    id: "601",
    name: "Oriental House ho fun",
    desc: "Flat rice noodles with stir fried vegetables, beef, chicken and king prawns cooked in gravy.",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "602",
    name: "Dry Beef ho fun",
    desc: "",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "603",
    name: "Beef and Green pepper in black bean sauce ho fun",
    desc: "",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "604",
    name: "Chicken and Green pepper in black bean sauce ho fun",
    desc: "",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "605",
    name: "King prawn and Green pepper in black bean sauce ho fun",
    desc: "",
    price: "£8.30",
    vegetarian: "",
  },
  {
    id: "606",
    name: "King prawn and vegetables ho fun",
    desc: "",
    price: "£8.30",
    vegetarian: "",
  },
];

export default HofunItems