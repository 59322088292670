const AppetisersItems = [
  {
    id: "101",
    name: "Crispy aromatic duck",
    desc: "Served with salad, pancake and plum sauce.",
    vegetarian: "",
    price: "£12.30",
    extra_1: "Extra Pancake",
    extra_2: "Extra Salad",
    extra_3: "Extra plum sauce (4oz)",
    extra_1_price: "£1.00",
    extra_2_price: "£2.00",
    extra_3_price: "£1.00",
  },
  {
    id: "102",
    name: "Deep fried special",
    desc: "3 chicken balls, 3 prawn balls, 1 stick rib and sweet and sour sauce.",
    vegetarian: "",
    price: "£5.00",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "103",
    name: "Sesame prawn toast",
    desc: "With sweet and sour sauce.",
    vegetarian: "",
    price: "£5.10",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "104",
    name: "Sweet and sour chicken balls (8 pcs)",
    desc: "",
    vegetarian: "",
    price: "£5.30",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "105",
    name: "Sweet and sour king prawn balls (8 pcs)",
    desc: "",
    vegetarian: "",
    price: "£6.30",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "106",
    name: "Spicy salt and pepper wings",
    desc: "With chilli, onion, spring onion and garlic.",
    vegetarian: "",
    price: "£5.30",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "107",
    name: "Fried tofu",
    desc: "With dipping sauce. Choose from curry, barbecue, sweet and sour or sweet chilli.",
    vegetarian: "V",
    price: "£7.30",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "108",
    name: "Dry long spare ribs (5pcs)",
    desc: "",
    vegetarian: "",
    price: "£6.50",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "109",
    name: "Spicy salt & pepper chips",
    desc: "With chilli, onion, spring onion and garlic.",
    vegetarian: "",
    price: "£4.10",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "110",
    name: "Chips in curry",
    desc: "",
    vegetarian: "",
    price: "£4.10",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "111",
    name: "Chips",
    desc: "",
    vegetarian: "V",
    price: "£3.10",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "112",
    name: "Two ribs and chips",
    desc: "",
    vegetarian: "",
    price: "£4.10",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "113",
    name: "Mini spring rolls",
    desc: "",
    vegetarian: "V",
    price: "£2.00",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "114",
    name: "Prawn crackers",
    desc: "",
    vegetarian: "",
    price: "£2.00",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "115",
    name: "Banana fritters with syrup",
    desc: "",
    vegetarian: "V",
    price: "£3.00",
    extra_1: "",
    extra_2: "",
  },
  {
    id: "116",
    name: "Pineapple fritters with syrup",
    desc: "",
    vegetarian: "V",
    price: "£3.00",
    extra_1: "",
    extra_2: "",
  },
];

export default AppetisersItems;
